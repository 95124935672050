import type { BannerHeight } from '../../../amplienceTypes/schemas/exported/banner-schema';
import type { Styling, Styling1 } from '../../../amplienceTypes/partials/exported/styling-schema';
import type {
  DefaultLayout1 as SectionColumnLayout,
  DefaultLayout,
} from '../../../amplienceTypes/partials/exported/section-schema';

export const gsPartialBannerHeight: { [key in BannerHeight]: string } = {
  'Homepage (L)': 'row--height400',
  'PLP (Moodbanner)': 'row--height150',
  XS: 'row--height150',
  S: 'row--height200',
  M: 'row--height300',
  L: 'row--height400',
  XL: 'row--height500',
};

export const gsPartialStyling: { [key in Styling[number] | Styling1[number]]: string } = {
  'theme landingpages': 'theme--landingpages',
  'clickable banner': 'contentBlock--clickable',
  'light color': 'contentBlock--light',
  'light color tablet': 'contentBlock--smLight',
  'light color desktop': 'contentBlock--mdLight',
  'dark color tablet': 'contentBlock--smDark',
  'dark color desktop': 'contentBlock--mdDark',
  'button type underline': 'contentBlock--buttonUnderline',
  'button type solid': 'contentBlock--buttonSolid',
  'button type link': 'contentBlock--buttonLink',
  'subTitle size default (40, 40 ,40)': '',
  'subTitle size xsmall (14, 20 ,20)': 'contentBlock--titleSize-xsmall',
  'subTitle size small (30, 30, 30)': 'contentBlock--titleSize-small',
  'subTitle size large (40, 50, 60)': 'contentBlock--titleSize-large',
  'subTitle size 20 tablet': 'contentBlock--smTitleSize-20',
  'subTitle size 30 tablet': 'contentBlock--smTitleSize-30',
  'subTitle size 40 tablet': 'contentBlock--smTitleSize-40',
  'subTitle size 50 tablet': 'contentBlock--smTitleSize-50',
  'subTitle size 60 tablet': 'contentBlock--smTitleSize-60',
  'subTitle size 80 tablet': 'contentBlock--smTitleSize-80',
  'subTitle size 20 desktop': 'contentBlock--mdTitleSize-20',
  'subTitle size 30 desktop': 'contentBlock--mdTitleSize-30',
  'subTitle size 40 desktop': 'contentBlock--mdTitleSize-40',
  'subTitle size 50 desktop': 'contentBlock--mdTitleSize-50',
  'subTitle size 60 desktop': 'contentBlock--mdTitleSize-60',
  'subTitle size 80 desktop': 'contentBlock--mdTitleSize-80',
  'title size 14 mobile': 'contentBlock--xsTitleSize-14',
  'title size 16 mobile': 'contentBlock--xsTitleSize-16',
  'title size 18 mobile': 'contentBlock--xsTitleSize-18',
  'title size 20 mobile': 'contentBlock--xsTitleSize-20',
  'title size 30 mobile': 'contentBlock--xsTitleSize-30',
  'title size 40 mobile': 'contentBlock--xsTitleSize-40',
  'title size 20 tablet': 'contentBlock--smTitleSize-20',
  'title size 30 tablet': 'contentBlock--smTitleSize-30',
  'title size 40 tablet': 'contentBlock--smTitleSize-40',
  'title size 50 tablet': 'contentBlock--smTitleSize-50',
  'title size 60 tablet': 'contentBlock--smTitleSize-60',
  'title size 80 tablet': 'contentBlock--smTitleSize-80',
  'title size 20 desktop': 'contentBlock--mdTitleSize-20',
  'title size 30 desktop': 'contentBlock--mdTitleSize-30',
  'title size 40 desktop': 'contentBlock--mdTitleSize-40',
  'title size 50 desktop': 'contentBlock--mdTitleSize-50',
  'title size 60 desktop': 'contentBlock--mdTitleSize-60',
  'title size 80 desktop': 'contentBlock--mdTitleSize-80',
  'image overlay': 'contentBlock--imageOverlay',
  'split left tablet': 'contentBlock--smSplitLeft',
  'split right tablet': 'contentBlock--smSplitRight',
  'split left desktop': 'contentBlock--mdSplitLeft',
  'split right desktop': 'contentBlock--mdSplitRight',
  ' ': '',
};

export const gsPartialLayout: { [key in DefaultLayout]: string } = {
  '': '',
  'HP Card Overview': '',
  'HP Categories': 'row--categories js-row--categories',
  'Layout 01 [Hero]': 'row-header',
  'Layout 02 [Carousel]': 'row-layout row--L02 row--carousel',
  'Layout 03 [Text, Mood Image, Key Image, Detail Image 01, Caption]': 'row--layout row--L03',
  'Layout 04 [Mood Image, Text, Detail Image 01, Detail Image 02, Key Image, Caption]':
    'row--layout row--L4',
  'Layout 05 [Mood Image, Detail Image 01, Caption, Key Image, Text]': 'row--layout row--L5',
  'Layout 06 [Mood Image, Text, Detail Image 01, Key Image, Caption]': 'row--layout row--L6',
  'Layout 07 [Key Image, Detail Image 01, Caption, Mood Image, Text]': 'row--layout row--L7',
  'Layout 08 [Mood Image]': 'row--layout row--L8',
  'Layout 09 [Text, Mood Image, Detail Image 01, Key Image, Caption]': 'row--layout row--L9',
  'Layout 10 [Key Image, Detail Image 01, Caption, Mood Image, Text]': 'row--layout row--L10',
  'Layout 11 [Mood Image, Text, Key Image, Detail Image 01, Caption]': 'row--layout row--L11',
  'Layout 12 [Mood Image]': 'row--layout row--L12',
  'Layout 13 [Caption, Key Image, Detail Image 01, Text, Detail Image 02]': 'row--layout row--L13',
  'Layout 14 [Caption, Mood Image, Key Image, Text, Detail Image 01]': 'row--layout row--L14',
  'Layout 15 [Text]': 'row--layout row--L15',
  'Layout 16 [Key Image, Detail Image 01, Caption, Text]': 'row--layout row--L16',
  'Layout 17 [Text, Key Image, Detail Image 01, Caption]': 'row--layout row--L17',
  'Layout 18 [Key Image, Caption, Text]': 'row--layout row--L18',
  'Layout 19 [Text, Key Image, Caption]': 'row--layout row--L19',
  'Layout 20 [Caption, Key Image, Detail Image 01, Text, Detail Image 02]': 'row--layout row--L20',
  'Layout 21 [Caption, Key Image, Detail Image 01, Text, Mood Image]': 'row--layout row--L21',
  'Layout 22 [Mood Image]': 'row--layout row--L22',
  'Layout 23 [Mood Image]': 'row--layout row--L23',
  'Layout 24 [Mood Image, Key Image, Detail Image 01, Caption, Text]': 'row--layout row--L24',
  'Layout 25 [Caption, Key Image, Detail Image 01, Text]': 'row--layout row--L25',
  'Layout 26 [Text]': 'row--layout row--L26',
  'Layout 27 [Text]': 'row--layout row--L27',
  'Layout 28 [Caption, Detail Image 01, Mood Image, Text]': 'row--layout row--L28',
  'Layout 29 [Mood Image, Text]': 'row--layout row--L29',
  'Layout 30 [Text, Carousel]': 'row--layout row--L30 row--productCarousel',
  'Layout 31 [Key Image, Detail Image 01, Text]': 'row--layout row--L31',
  'Layout 32 [Key Image, Detail Image 01, Text]': 'row--layout row--L32',
  'Layout 33 [Text, Key Image, Mood Image]': 'row--layout row--L33 has-buttonUnderline',
  'Layout 34 [Text, Key Image, Mood Image]': 'row--layout row--L34 has-buttonUnderline',
  'Layout 35 [Text, Mood Image]': 'row--layout row--L35 has-buttonUnderline',
  'Layout 36 [Text, Mood Image]': 'row--layout row--L36 has-buttonUnderline',
  'Layout 37 [Mood Image, Key Image, Detail Image 01]': 'row--layout row--L37',
  'Layout 38 [Key Image, Caption, Text]': 'row--layout row--L38 has-buttonUnderline',
  'Layout 39 [Key Image, Caption, Text]': 'row--layout row--L39 has-buttonUnderline',
  'Layout 40 [Text, Detail Image 01]': 'row--layout row--L40 has-buttonUnderline',
  'Layout 41 [Text, Detail Image 01]': 'row--layout row--L41 has-buttonUnderline',
  'Layout 42 [Text, Detail Image 01, Key Image, Detail Image 02, Caption, Mood Image]':
    'row--layout row--L42 has-buttonUnderline',
  'Layout 43 [Text, Detail Image 01, Key Image, Detail Image 02, Caption, Mood Image]':
    'row--layout row--L43 has-buttonUnderline',
  'Layout 44 [Text]': 'row--layout row--L44 row--filter',
};

export const gsPartialColumnLayout: { [key in SectionColumnLayout]: string } = {
  'Mood Image': 'col24 col--image-mood',
  'Key Image': 'col24 col--image-key',
  'Detail Image 01': 'col24 col--image-detail col--image-detail01',
  'Detail Image 02': 'col24 col--image-detail col--image-detail02',
  Text: 'col24 col--text',
  Caption: 'col24 col--caption',
  Carousel: 'col24 col--carousel',
  Hero: 'col24 col--main',
};

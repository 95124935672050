import type { PropsWithChildren, CSSProperties } from 'react';
import { forwardRef } from 'react';
import type { Attributes as AttributesType } from '../../../amplienceTypes/partials/exported/element-attributes-schema';
import { removeUselessSpaces } from '../../../utilities/parsers';

interface AttributesInterface {
  additionalClassNames?: string;
  className?: string;
  ariaRole?: string;
  ariaLabel?: string;
}

const Attributes = forwardRef<
  HTMLDivElement,
  PropsWithChildren<AttributesType & AttributesInterface>
>((props, ref) => {
  const {
    dataAttributes,
    classNames,
    id,
    additionalClassNames,
    className,
    ariaRole,
    ariaLabel,
    children,
    ...rest
  } = props;

  const makeDataAttributes = () => {
    const attributes: { [key: string]: string | boolean } = {};
    const cssStyles: { [key: string]: string } = {};

    dataAttributes?.forEach(attribute => {
      if (attribute.name === 'style' && attribute.value) {
        const styles = attribute.value
          .trim()
          .split(';')
          .filter(style => style);

        styles.forEach(style => {
          const [property, value] = style.trim().split(':');

          cssStyles[property.trim()] = value.trim();
        });
      } else if (attribute.name === 'hidden') {
        attributes[attribute.name] = attribute.value === 'true';
      } else if (attribute.name && attribute.value) {
        attributes[attribute.name] = attribute.value;
      }
    });

    return { attributes, cssStyles };
  };

  const getIdAttr = () => (id ? { id } : {});

  const classes = removeUselessSpaces(
    `${additionalClassNames ?? ''} ${classNames?.join(' ') ?? ''} ${className ?? ''}`
  );

  return (
    <div
      {...getIdAttr()}
      {...(classes ? { className: classes } : {})}
      {...makeDataAttributes().attributes}
      {...rest}
      style={makeDataAttributes().cssStyles as CSSProperties}
      role={ariaRole}
      aria-label={ariaLabel}
      ref={ref}
    >
      {children}
    </div>
  );
});

Attributes.displayName = 'Attributes';

export default Attributes;

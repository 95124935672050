import type { ReactElement } from 'react';
import { Fragment } from 'react';
import type {
  ResponsiveImage1,
  ResponsiveImage2,
  ResponsiveBodyImage,
  ResponsiveBodyImages,
} from '../../../amplienceTypes/partials/exported/responsive-image-schema';

const ImageSource = ({
  images,
  desktop,
  tablet,
  mobile,
}: ResponsiveImage1 & ResponsiveImage2): ReactElement => {
  const desktopSrc = desktop || images?.desktop;
  const tabletSrc = tablet || images?.tablet;
  const mobileSrc = mobile || images?.mobile;

  const sources = [
    {
      src: desktopSrc,
      params: images?.desktopParams,
      minWidth: 1024,
      width: 1680,
    },
    {
      src: tabletSrc,
      params: images?.tabletParams,
      minWidth: 768,
      width: 1024,
    },
    {
      src: mobileSrc,
      params: images?.mobileParams,
      minWidth: 0,
      width: 768,
    },
  ];

  return (
    <>
      {sources.map((source, i) =>
        source.src ? (
          <source
            key={i}
            media={`(min-width: ${source.minWidth}px)`}
            srcSet={`https://${source.src.defaultHost}/i/${source.src.endpoint}/${encodeURI(
              source.src.name ?? ''
            )}?${source.params || `fmt=auto&w=${source.width}&upscale=false`}`}
          />
        ) : (
          <Fragment key={i} />
        )
      )}
    </>
  );
};

const BodyImageSource = ({
  desktop,
  tablet,
  mobile,
  images,
}: ResponsiveBodyImage & ResponsiveBodyImages): ReactElement => {
  const sources = [
    {
      src: desktop || images?.desktop,
      minWidth: 1024,
    },
    {
      src: tablet || images?.tablet,
      minWidth: 768,
    },
    {
      src: mobile || images?.mobile,
      minWidth: 0,
    },
  ];

  return (
    <>
      {sources.map((source, i) =>
        source.src ? (
          <source
            key={i}
            media={`(min-width: ${source.minWidth}px)`}
            srcSet={`
            //${source.src.image?.defaultHost}/i/${source.src.image?.endpoint}/${encodeURI(
              source.src.image?.name ?? ''
            )}${
              source.src.paddingLeft || source.src.paddingRight
                ? `?$padding$&gs_pl=${source.src.paddingLeft ?? 0}&gs_pr=${
                    source.src.paddingRight ?? 0
                  }&gs_src=${encodeURI(source.src?.image?.name ?? '')}`
                : ''
            }`}
          />
        ) : (
          <Fragment key={i} />
        )
      )}
    </>
  );
};

export { ImageSource, BodyImageSource };

import type { ReactElement } from 'react';
import { useEffect, useRef } from 'react';
import styled from 'styled-components';
import dynamic from 'next/dynamic';
import type { ContentBlock as SimpleVideoBannerContentBlock } from '../../../amplienceTypes/schemas/exported/simple-video-banner-schema';
import type { ContentBlock as SimpleBannerContentBlock } from '../../../amplienceTypes/schemas/exported/simple-banner-schema';
import type { ContentBlock as BannerContentBlock } from '../../../amplienceTypes/schemas/exported/banner-schema';
import type { ContentBlock as LocalizedBannerContentBlock } from '../../../amplienceTypes/schemas/exported/localized-banner-schema';
import type { ContentBlock as LogoBannerContentBlock } from '../../../amplienceTypes/schemas/exported/logo-banner-schema';
import type { SectionHotspot } from '../../../amplienceTypes/schemas/exported/section-hotspot-schema';
import type { SectionLocalizedBanner } from '../../../amplienceTypes/schemas/exported/section-localized-banner-schema';
import type { SectionText } from '../../../amplienceTypes/schemas/exported/section-text-schema';
import type { Title as SectionImageText } from '../../../amplienceTypes/schemas/exported/section-image-text-schema';
import type { SectionBanner } from '../../../amplienceTypes/schemas/exported/section-banner-schema';
import type { Title as SectionImage } from '../../../amplienceTypes/schemas/exported/section-image-schema';
import type { VerticalAlignmentMiddleByDefault } from '../../../amplienceTypes/partials/exported/layout-schema';
import Attributes from './Attributes';
import { BodyImageSource, ImageSource } from './ImageSources';
import { Button } from '../templates/Button';
import { gsPartialStyling } from '../../../utilities/constants/homepageHandlebars';
import { hasHotspots } from '../../../utilities/amplience/hotspots';

const capitalizeFirst = (text: string) => `${text.charAt(0).toUpperCase()}${text.slice(1)}`;

const S = {
  Title: styled.div``,
  SubTitle: styled.div``,
  Body: styled.div``,
};

const VimeoPlayer = dynamic(async () => (await import('../VimeoPlayer')).VimeoPlayer, {
  ssr: false,
});

const Hotspots = dynamic(async () => (await import('./Hotspots')).Hotspots, {
  ssr: false,
});

export type ContentBlockDataSchema = SimpleVideoBannerContentBlock &
  SimpleBannerContentBlock &
  BannerContentBlock &
  LocalizedBannerContentBlock &
  LogoBannerContentBlock &
  SectionHotspot &
  SectionLocalizedBanner &
  SectionText &
  SectionImageText &
  SectionBanner &
  SectionImage;

export const ContentBlock = ({
  attributes,
  styling,
  layout,
  responsiveImage,
  vimeo,
  contentBody,
  seo,
}: ContentBlockDataSchema): ReactElement => {
  const renderHotspots = responsiveImage?.images && hasHotspots(responsiveImage?.images);
  const contentBlockEl = useRef<HTMLDivElement>(null);
  const pictureImageEl = useRef<HTMLImageElement>(null);
  const stylingClasses = styling?.map(s => gsPartialStyling[s]).join(' ');

  const getVerticalAlignmentClass = () => {
    const verticalAlignment = layout?.verticalAlignment as VerticalAlignmentMiddleByDefault;

    return `${
      verticalAlignment?.mobile
        ? ` contentBlock--xsBodyVerticalAlign${capitalizeFirst(verticalAlignment.mobile)}`
        : ''
    }${
      verticalAlignment?.tablet
        ? ` contentBlock--smBodyVerticalAlign${capitalizeFirst(verticalAlignment.tablet)}`
        : ''
    }${
      verticalAlignment?.desktop
        ? ` contentBlock--mdBodyVerticalAlign${capitalizeFirst(verticalAlignment.desktop)}`
        : ''
    }`;
  };

  useEffect(() => {
    if (contentBlockEl.current) {
      const contentBlockBodyLinkNodeList = contentBlockEl.current.querySelectorAll(
        '.js-contentBlock-body-link'
      );
      const contentBlockBodyLink: Element[] = [];
      let clickList: Element[] = [];
      const hitareaList: HTMLElement[] = [];

      contentBlockBodyLinkNodeList.forEach(elm => {
        contentBlockBodyLink.push(elm);
      });

      if (contentBlockBodyLink.length > 2) {
        clickList = contentBlockBodyLink.slice(2);
      } else {
        clickList = contentBlockBodyLink;
      }

      if (clickList.length > 0) {
        clickList.forEach(elm => {
          const hitarea = document.createElement('div');

          hitarea.classList.add('hitarea');
          contentBlockEl.current?.appendChild(hitarea);
          hitareaList.push(hitarea);
          hitarea.addEventListener('click', event => {
            if (event.type === 'click') {
              (elm as HTMLElement).click();
            }
          });

          [hitarea, elm].forEach(element => {
            element.addEventListener('mouseenter', () => {
              contentBlockEl.current?.classList.add('is-hovered');
            });
            element.addEventListener('mouseleave', () => {
              contentBlockEl.current?.classList.add('is-hovered');
            });
          });
        });

        if (clickList.length === 2) {
          const rectData = contentBlockEl.current.getBoundingClientRect();
          let minWidth: number;

          clickList.forEach((elm, i) => {
            const elmRectData = elm.getBoundingClientRect();
            const htmlElm = elm as HTMLElement;

            hitareaList[i].style.width = '';
            hitareaList[i].style.left = '';
            hitareaList[i].style.right = '';

            if (i === 0) {
              const right =
                (elmRectData.left +
                  htmlElm.offsetWidth +
                  parseInt(htmlElm.style.marginRight || '0', 10) -
                  rectData.left) /
                rectData.width;

              hitareaList[i].style.right = `${100 - right * 100}%`;
            } else {
              const localLeft =
                (elmRectData.left - parseInt(htmlElm.style.marginLeft || '0', 10) - rectData.left) /
                rectData.width;

              hitareaList[i].style.left = `${localLeft * 100}%`;
            }

            const width = hitareaList[i].offsetWidth / rectData.width;

            minWidth = minWidth && minWidth < width ? minWidth : width;
          });

          clickList.forEach((elm, i) => {
            hitareaList[i].style.width = `${minWidth * 100}%`;

            if (i === 0) {
              hitareaList[i].style.left = 'auto';
            } else {
              hitareaList[i].style.right = 'auto';
            }
          });
        }
      }
    }
  }, []);

  return (
    <Attributes
      {...attributes}
      additionalClassNames={`contentBlock js-contentBlock contentBlock--titleUltra contentBlock--subTitleUltra${
        layout?.textAlignment?.tablet
          ? ` contentBlock--smBodyTextAlign${capitalizeFirst(layout.textAlignment.tablet)}`
          : ''
      }${
        layout?.textAlignment?.desktop
          ? ` contentBlock--mdBodyTextAlign${capitalizeFirst(layout.textAlignment.desktop)}`
          : ''
      }${getVerticalAlignmentClass()}${vimeo ? ' contentBlock--vimeoBackground' : ''} ${
        stylingClasses ?? ''
      }`}
      {...(vimeo?.mobile ? { 'data-vimeo-xs': vimeo.mobile } : {})}
      {...(vimeo?.tablet ? { 'data-vimeo-sm': vimeo.tablet } : {})}
      {...(vimeo?.desktop ? { 'data-vimeo-md': vimeo.desktop } : {})}
      ref={contentBlockEl}
    >
      {responsiveImage && (
        <picture
          className={`contentBlock-picture js-contentBlock-picture ${
            renderHotspots ? 'js-has-hotspots' : ''
          }`}
        >
          <ImageSource {...responsiveImage} />
          <img
            ref={pictureImageEl}
            decoding="async"
            className={`contentBlock-picture-image js-contentBlock-picture-image ${
              renderHotspots ? 'js-has-hotspots-image' : ''
            }`}
            alt={responsiveImage.alt ?? 'responsive-image'}
            loading="lazy"
          />
        </picture>
      )}

      {vimeo && <VimeoPlayer {...vimeo} contentBlockEl={contentBlockEl} />}

      {contentBody && (
        <div
          className={`contentBlock-body js-contentBlock-body${
            layout?.tabletOffset ? ` contentBlock-body--smOffset${layout.tabletOffset}` : ''
          }${layout?.desktopOffset ? ` contentBlock-body--mdOffset${layout.desktopOffset}` : ''}`}
        >
          <div
            className={`contentBlock-bodyAlign${
              layout?.tabletWidth ? ` contentBlock-bodyAlign--smWidth${layout.tabletWidth}` : ''
            }${
              layout?.desktopWidth ? ` contentBlock-bodyAlign--mdWidth${layout.desktopWidth}` : ''
            }${
              layout?.textAlignment?.mobile
                ? ` contentBlock-bodyAlign--smTextAlign${capitalizeFirst(
                    layout?.textAlignment?.mobile
                  )}`
                : ''
            }`}
          >
            {layout?.verticalOffset && (
              <>
                {layout.verticalOffset.mobile && (
                  <div
                    className="is-mobile is-visible--xxsmall"
                    style={{ marginTop: layout.verticalOffset.mobile }}
                  />
                )}
                {layout.verticalOffset.tablet && (
                  <div
                    className="is-tablet is-visible--xsmall is-visible--small"
                    style={{ marginTop: layout.verticalOffset.tablet }}
                  />
                )}
                {layout.verticalOffset.desktop && (
                  <div
                    className="is-desktop is-hidden--xxsmall is-hidden--xsmall is-hidden--small"
                    style={{ marginTop: layout.verticalOffset.desktop }}
                  />
                )}
              </>
            )}

            {contentBody.responsiveBodyImage && (
              <picture className="contentBlock-body-picture js-contentBlock-body-picture">
                <BodyImageSource {...contentBody.responsiveBodyImage} />
                <img
                  decoding="async"
                  className="contentBlock-body-picture-image js-contentBlock-body-picture-image"
                  alt={contentBody.responsiveBodyImage.alt ?? 'responsive-body-image'}
                  loading="lazy"
                />
              </picture>
            )}

            {contentBody.title && (
              <S.Title
                className="contentBlock-body-title js-contentBlock-body-title"
                suppressHydrationWarning
                dangerouslySetInnerHTML={{
                  __html:
                    (contentBody.title.htmlEditor?.html as string) || (contentBody.title as string),
                }}
                as={seo?.title || 'h2'}
              />
            )}

            {contentBody.subTitle && (
              <S.SubTitle
                className="contentBlock-body-subTitle js-contentBlock-body-subTitle"
                suppressHydrationWarning
                dangerouslySetInnerHTML={{
                  __html:
                    (contentBody.subTitle.htmlEditor?.html as string) ||
                    (contentBody.subTitle as string),
                }}
                as={seo?.subTitle || 'h3'}
              />
            )}

            {contentBody.body && (
              <S.Body
                className="contentBlock-body-text js-contentBlock-body-text"
                suppressHydrationWarning
                dangerouslySetInnerHTML={{
                  __html:
                    (contentBody.body.htmlEditor?.html as string) || (contentBody.body as string),
                }}
                as={seo?.body || 'div'}
              />
            )}

            {contentBody.buttons && contentBody.buttons.length > 0 && (
              <div className="contentBlock-body-links js-contentBlock-body-links">
                {contentBody.buttons.map((button, i) => (
                  <Button key={i} {...button} />
                ))}
              </div>
            )}
          </div>
        </div>
      )}

      {responsiveImage?.images && renderHotspots && (
        <Hotspots images={responsiveImage.images} imageRef={pictureImageEl} />
      )}
    </Attributes>
  );
};
